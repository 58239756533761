import React, { useEffect, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import { SpotlightManager, SpotlightTransition, Spotlight } from '@atlaskit/onboarding';
import { Layering } from '@atlaskit/layering';

import { useRouteActions, createSingleQueryParamHook } from '@confluence/route-manager';

export const Nav4OptOutSpotlight = ({ children }) => {
	const intl = useIntl();
	const navOptOutQueryParam = Boolean(useNavOptOutQueryParam());
	const [shouldRender, setShouldRender] = useState(navOptOutQueryParam);

	const { setQueryParams } = useRouteActions();

	useEffect(() => {
		if (navOptOutQueryParam) {
			setQueryParams({ navOptOut: null });
		}
	}, [navOptOutQueryParam, setQueryParams]);

	if (!shouldRender) {
		return <>{children}</>;
	}

	return (
		<SpotlightManager onBlanketClicked={() => setShouldRender(false)}>
			{children}
			<SpotlightTransition>
				<Layering isDisabled={false}>
					<Spotlight
						actions={[
							{
								onClick: () => setShouldRender(false),
								text: intl.formatMessage(i18n.ok),
							},
						]}
						heading={intl.formatMessage(i18n.heading)}
						target="nav-profile"
					>
						{intl.formatMessage(i18n.content)}
					</Spotlight>
				</Layering>
			</SpotlightTransition>
		</SpotlightManager>
	);
};

const useNavOptOutQueryParam = createSingleQueryParamHook('navOptOut');

const i18n = defineMessages({
	heading: {
		id: 'app-navigation.nav4-opt-out-spotlight.heading',
		defaultMessage: 'Re-enable the new navigation',
		description:
			'Heading for the spotlight that explains how to re-enable the new navigation after opting out',
	},
	content: {
		id: 'app-navigation.nav4-opt-out-spotlight.content',
		defaultMessage:
			'Click your avatar and select “Turn on the new navigation” to give it another chance.',
		description:
			'Content for the spotlight that explains how to re-enable the new navigation after opting out',
	},
	ok: {
		id: 'app-navigation.nav4-opt-out-spotlight.ok',
		defaultMessage: 'OK',
		description: 'Text for the OK button in the spotlight',
	},
});
